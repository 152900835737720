/**
 * parses array with all softwares and their corresponding AWVs (single) und keyUsers[]
 * @param {*} data input string to parse data from
 * @returns String[][][]: [software[awv, [keyUsers]]]
*/
export const parseAWVAndKeyUserProperty = (data: string) => {
    const AWV_AND_KEYUSERS = data.split("-");
    const AWV = AWV_AND_KEYUSERS[0].split(":");
    const KEY = AWV_AND_KEYUSERS[1].split(":");
    const AWV_SOFTWARE = AWV[1].split(",");
    const KEY_SOFTWARE = KEY[1].split(",");
    return [AWV_SOFTWARE, KEY_SOFTWARE];
}
