/**
 * @author Raban Noack <raban.noack@vic-gmbh.net>
 */

import React, { useEffect, useState } from "react";
// @ts-ignore
import { UserDetailedView } from "./userDetailedView.tsx";
import 'boxicons';

export function UserOverview(props) {

    const [ detail, setDetail ] = useState(props.expandAll);

    const handleClick = () => {
        detail? setDetail(false) : setDetail(true);
    }

    useEffect(() => {
        setDetail(false);
    }, [props.user.id]);

    useEffect(() => {
        setDetail(props.expandAll);
    }, [props.expandAll]);

    //TODO: max lenght von media-query abhaengig machen
    const maxTextLength = (text: string, number: number): string => {
        if(text) { 
            return text.length >= number ?
                `${text.slice(0, number)}...`
                :
                text
        }
        return "";
    }
    
    //console.log(`UserOverview: ${JSON.stringify(props.user)}`)

    return(
        <div className="userOverview" >

            <div className="detailButton">
                <button type="button" onClick={handleClick} className="myButtons">
                    {/*@ts-ignore */}
                    <box-icon name={detail ? "chevron-up" : "chevron-down"}></box-icon>
                </button>
            </div>

            {props.user.picture}
            
            <div className="userGrid">
                <span className="userName">
                    <strong>
                        {`${props.user.displayName}`}
                    </strong>
                </span>

                <span className="jobTitle">
                    {props.user.jobTitle ? maxTextLength(props.user.jobTitle, 25) : null}
                </span>

                <span className="department">
                    {props.user.department ? maxTextLength(props.user.department, 65) : null}
                </span>

                {<span className="city">
                    {props.user.city ? props.user.city : null}
                </span>}
                
                <div className="sendMailButton">
                    <button type="button" onClick={() => window.open(`mailto:${props.user.mail}`)} className="myButtons-grid">
                        {/*@ts-ignore */}
                        <box-icon
                            name="mail-send"
                            alt={`E-Mail an ${props.user.mail} senden`} 
                            title={`E-Mail an ${props.user.mail} senden`}>
                        {/*@ts-ignore */}
                        </box-icon>
                    </button>
                </div>

            </div>

            {detail || window.matchMedia("(max-width: 800px)").matches ? <UserDetailedView user={props.user} /> : null}
            
        </div>
    )
}