/**
 * @author Raban Noack <raban.noack@vic-gmbh.net>
 */

import React, { useState } from "react";
import 'boxicons';

export function UserDetailedView(props) {

        /**
     * 
     * @param number 
     * @param mobile 
     * @returns 
     */
    const formatPhoneNumbers = (number, mobile: boolean): string => {
        let s = number.replaceAll(" ", "");
        s = s.replaceAll("-", "");
        s = s.replace("+49", "0");
        s = s.replace("+", "0");
        mobile ? 
            s = "Mobil (" + s.slice(0, 4) + " " + s.slice(4, 7) + " " + s.slice(7) + ")"
            :
            props.user.city === "Berlin" ?
                s = "Tel (" + s.slice(0, 3) + " " + s.slice(3, 7) + " " + s.slice(7) + ")"
                :
                s = "Tel (" + s.slice(0, 4) + " " + s.slice(4, 8) + " " + s.slice(8) + ")";
        return s;
    }

    return(
        <div className="userDetailedView">   

            {<span className="businessphones">
                {props.user.businessPhones[0] && (props.user.businessPhones[0] !== props.user.mobilePhone) ?
                    <div>
                        <div className="noNumber">
                            {formatPhoneNumbers(props.user.businessPhones[0], false) + ""}
                            <CopyToClipboard data={props.user.businessPhones[0]} />
                        </div>
                        <div className="callButton">
                            {/*@ts-ignore */}
                            <box-icon
                                name="phone"
                                size="l"
                                title={`${props.user.displayName} auf dem Festnetz anrufen`}
                                alt={`${props.user.displayName} auf dem Festnetz anrufen`}
                                onClick={() => window.open(`tel:${props.user.businessPhones}`)}>
                            {/*@ts-ignore */}
                            </box-icon>
                            
                        </div>
                        
                    </div>
                    : 
                    null
                }
            </span>}

            {<span className="mobilephone">
                {props.user.mobilePhone ?
                    <div>
                        <div className="noNumber">
                            {formatPhoneNumbers(props.user.mobilePhone, true) + ""}
                            <CopyToClipboard data={props.user.mobilePhone} />
                        </div>
                        <div className="callButton">
                            {/*@ts-ignore */}
                            <box-icon
                                name="mobile-alt"
                                size="l"
                                title={`${props.user.displayName} auf dem Handy anrufen`}
                                alt={`${props.user.displayName} auf dem Handy anrufen`}
                                onClick={() => window.open(`tel:${props.user.mobilePhone}`)}>
                            {/*@ts-ignore */}
                            </box-icon>
                        </div>
                        
                    </div>
                    :
                    null
                }
            </span>}

            <span className="company">
                {props.user.companyName ? props.user.companyName : null}
            </span>

            <span className="mail">
                {props.user.mail ? 
                    <div>
                        {props.user.mail}
                        <CopyToClipboard data={props.user.mail} />
                    </div>
                    : 
                    null}
            </span>
            
        </div>       
    );
}

function CopyToClipboard(props) {

    const [ name, setName ] = useState("copy");
    const [ type, setType ] = useState("");
    const [ className, setClassName ] = useState("copyToClipboard");
    const [ animation, setAnimation ] = useState("");

    const handleCopyToClipboard = (data) => {
        
        navigator.clipboard.writeText(data);
        setClassName("copyToClipboardPressed");
        setName("check-circle");
        setType("solid");
        setAnimation("burst");
        setTimeout(() => {
            setType("");
            setAnimation("");
            setName("copy");
            setClassName("copyToClipboard");
        }, 1050);
    };

    return (
        <div className={className}>
            {/*@ts-ignore */}
            <box-icon
                type={type}
                name={name}
                animation={animation}
                size="xs"
                title="in Zwischenablage kopieren"
                alt="in Zwischenablage kopieren"
                onClick={() => handleCopyToClipboard(props.data)}>
            {/*@ts-ignore */}
            </box-icon>
        </div>
    );
}