/**
 * @author Raban Noack <raban.noack@vic-gmbh.net>
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import  { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);
//const msalInstance = new ConfidentialClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);