/**
 * @author Raban Noack <raban.noack@vic-gmbh.net>
 */

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraphAllUsers(accessToken) {

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    //for advanced query parameters
    headers.append("ConsistencyLevel", "eventual");
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    //gibt max 500 User in unserem Tenant zurueck
    const allUsers = fetch("https://graph.microsoft.com/v1.0/users?$count=true&$filter=usertype eq 'member' and surname ne null and city ne null and accountEnabled eq true&$select=city,companyname,department,jobtitle,mail,displayname,id,surname,givenname,businessphones,mobilephone,usertype&$top=500", options)
    .then(response => response.json())
    .catch(error => console.log(error));

    return (allUsers);
}

export async function callMsGraphPic(accessToken, name) {

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const profilePic = fetch(`https://graph.microsoft.com/v1.0/users/${name}/photos/48x48/$value`, options)
    .then(response => response.blob())
    .catch(error => console.log(error));

    return (profilePic);
}

/*
export async function searchUsers(accessToken, searchData) {

    const headers = new Headers();
    //for advanced query parameters
    headers.append("ConsistencyLevel", "eventual");
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const users = fetch(renderGraphCall(searchData), options)
    .then(response => response.json())
    .catch(error => console.log(error));

    return (users);
}

function renderGraphCall(request) {
    let multiFilter = false;
    let graphQuery =  "https://graph.microsoft.com/v1.0/users?$count=true";
    if(request.name) {
        graphQuery += `&$search="displayName:${request.name}"`;
    }
    if(request.businessPhone) {
        graphQuery += `${multiFilter ? " and " : "&$filter="}businessPhones/any(p:p le '${request.businessPhone}')`;
        multiFilter = true;
    }
    if(request.mobilePhone) {
        graphQuery += `${multiFilter ? " and " : "&$filter="}mobilePhone/any(s:s eq '${request.mobilePhone}')`;
        multiFilter = true;
    }
    if(request.city) {
        graphQuery += `${multiFilter ? " and " : "&$filter="}city eq '${request.city}'`;
        multiFilter = true;
    } 
    if(request.companyName) {
        graphQuery += `${multiFilter ? " and " : "&$filter="}companyName eq '${request.companyName}'`;
        multiFilter = true;
    } 
    if(request.department) {
        graphQuery += `${multiFilter ? " and " : "&$filter="}startsWith(department,'${request.department}')`;
        multiFilter = true;
    }
    if(request.jobTitle) {
        graphQuery += `${multiFilter ? " and " : "&$filter="}startsWith(jobTitle,'${request.jobTitle}')`;
        multiFilter = true;
    }
    //only AD accounts of type "member" with valid "surname" and "city" property
    graphQuery += `${multiFilter ? " and " : "&$filter="}usertype eq 'member' and surname ne null and city ne null`;
    //just the properties needed
    graphQuery += "&$select=city,companyname,department,jobtitle,mail,displayname,id,surname,givenname,businessphones,mobilephone,usertype"
    //top 500 Accounts
    graphQuery += "&$top=500";
    //graphQuery += "&$format=application/json"
    //graphQuery += "&$orderby=displayName desc";
    //console.log(`Graph API Call: "${graphQuery}"`);
    return graphQuery;
}
*/