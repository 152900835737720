/**
 * @author Raban Noack <raban.noack@vic-gmbh.net>
 */

export const msalConfig = {
    auth: {
      clientId: "566b5fb0-1039-41b0-8e60-d194403a7039",
      authority: "https://login.microsoftonline.com/520b7aa8-c280-404a-a72c-a80dff792d39",
      //redirectUri: "http://localhost:3000/auth-response",
      redirectUri: "https://vic-websiten-telefonbuch.azurewebsites.net",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

//for push again
  
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [
        'calendars.read',
        'user.read'
    ]
};

