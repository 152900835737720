/**
 * @author Raban Noack <raban.noack@vic-gmbh.net>
 */

import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react"
import { loginRequest } from "../../authConfig";
import { callMsGraphPic } from "../../graph";

export function ProfilePic(props) {

    const { instance, accounts } = useMsal();
    const [pic, setPic] = useState("");

    // TODO: Token nur bei erstem rendern anfordern und in const speichern
    function requestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraphPic(response.accessToken, props.id).then(response => {
                //@ts-ignore
                if(response.type === "image/jpeg") {
                    //@ts-ignore
                    setPic(URL.createObjectURL(response)); 
                } else {
                    setPic("");
                    
                }       
            });
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraphPic(response.accessToken, props.id).then(response => {
                    //@ts-ignore
                    if(response.type === "image/jpeg") {
                        //@ts-ignore
                        setPic(URL.createObjectURL(response)); 
                    } else {
                        setPic("");
                    }
                });
            });
        });
    }

    useEffect(() => {
        requestProfileData();
    }, []);

    /**
     * @returns calculated color from first letter of surname and givenName
     */
    const getColor = () => {
        const c = ((props.surname.charCodeAt(0) - 65) * 4) + ((props.givenName.charCodeAt(0) - 65) * 9);
        return `rgb(${c - 100}, ${c - 10}, ${c - 100})`;
    }

    /**
     * background-color attribute for fallback profile image
     */
    const color = {
        background: `${getColor()}`
    };

    return (
        <div className="picture">
            {pic ?
                <div>
                    <img alt="Profilbild" src={pic}></img>
                </div>
                :
                <div id="initialsContainer" style={color}>
                    <div id="initials">
                        {props.givenName[0].toUpperCase() + props.surname[0].toUpperCase()}
                    </div>
                </div>}
        </div> 
    )
}
