/**
 * @author Raban Noack <raban.noack@vic-gmbh.net>
 */

import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { loginRequest } from "../../authConfig";
import { callMsGraphAllUsers } from "../../graph";
import { UserList } from "../users/userList.tsx";
// eslint-disable-next-line
import 'boxicons';
import { Parser } from '@json2csv/plainjs';
// @ts-ignore
import { ProfilePic } from "../events/ProfilePic.tsx";
import * as util from "../util.ts";

export function Search() {

    const { instance, accounts } = useMsal();
    const [ users, setUsers ] = useState(null);
    const [ searchResponse, setSearchResponse ] = useState(null);
    const [ init, setInit ] = useState(true);
    const [ hidden, setHidden ] = useState(false);
    const [ expandAll, setExpandAll ] = useState(false);
    const [ sortBy, setSortBy ] = useState("surname");
    const [ sortDirection, setSortDirection ] = useState("increase");
    const [ options, setOptions ] = useState(null);
    const [ searchData ] = useState({
        name: "",
        phone: "",
        email: "",
        city: "",
        companyName: "",
        department: "",
        jobTitle: ""
    });

    const name = accounts[0] && accounts[0].name;

    users && users.value.forEach(user => {
        user.picture = <ProfilePic id={user.id} givenName={user.givenName} surname={user.surname}/>;
    });

    function requestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraphAllUsers(response.accessToken).then(response => {
                setUsers(response); 
                setOptions(getOptions(response.value))           
            });
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraphAllUsers(response.accessToken).then(response => {
                    //setUsers(response); 
                });
            });
        });
    }

    useEffect(() => {
        requestProfileData();
    },[]); //eslint-disable-line   

    /*
    useEffect(() => {
        if (!init) setOptions(getOptions(searchResponse));
    },[searchResponse]);
    */

    /**
     * sets triggered searchData-object property on onChange event
     * @param {*} event input that triggered the event
     * @returns 
     */
    function handleChange(event) {
        let value = event.target.value;
        switch(event.target.id) {
            case "name": 
                searchData.name = value;
                break;
            case "phone":
                searchData.phone = value.toString();
                break;
            case "city":
                searchData.city = value;
                break;
            case "companyName":
                searchData.companyName = value;
                break;
            case "department":
                searchData.department = value;
                break;
            case "jobTitle":
                searchData.jobTitle = value;
                break;
            default:
                return;
        }
        // if form is not empty send request
        if(!(isEmptyForm())) {
            setSearchResponse(search(searchData));
            setInit(false);
        } else {
            setToInitialState();
        }
    }

    const search = (data) => {
        let numberSearchCriteria = getNumberSearchCriteria(data);
        return users.value.filter(user => {
            let count = 0;
            for (var key in data) {
                if(key === "name" && data.name) {
                    if (user.surname.toLowerCase().startsWith(data.name.toLowerCase().trim())
                    || user.givenName.toLowerCase().startsWith(data.name.toLowerCase().trim())) {
                        count++
                    };
                }
                if(key === "phone" && data.phone && user.mobilePhone) {
                    if (formatPhoneNumbers(user.mobilePhone).startsWith(data.phone)){
                        count++
                    };
                }
                if(key === "phone" && data.phone && user.businessPhones[0]) {
                    if (formatPhoneNumbers(user.businessPhones[0]).startsWith(data.phone)){
                        count++
                    };
                }
                if (data[key]){
                    if (user[key]) {
                        if (user[key].includes(data[key])) {
                            count++;
                        }
                    }
                } else {
                    continue;
                }
            }
            return count === numberSearchCriteria;
        });
    }

    //TODO: volltextsuche auf mobilen geraeten
    /*
    const fullTextSearch = (data) => {
        return users.value.filter(user => {
            user.surname.startsWith(data) 
            || user.givenName.startsWith(data) 
            || UND SO WEITER...
        });
    }
    */

    const formatPhoneNumbers = (number) => {
        let s = number.replace("+49", "0");
        s = s.replace("+", "0");
        return s;
    }

    //counts searchData-object entrys with values != ""
    const getNumberSearchCriteria = (data) => {
        let counter = 0;
        Object.keys(data).forEach((value) => {
            if (data[value] !== ""){
                counter++;
            }
        });
        return counter; 
    }; 

    /**
     * @returns boolean if formular is empty
     */
    const isEmptyForm = () => {
        let empty = true;
        Object.keys(searchData).forEach((value) => {
            console.log("\"" + searchData[value].toUpperCase() + "\"");
            if(searchData[value].trimStart()) {
                empty = false;
            }
        });
        return empty;
    }

    /**
     * clear formular, search-object & DOM
     */
    const handleReset = () => {
        //clear form
        var form = document.getElementById("searchForm");
        form.reset();
        //clear search object
        Object.keys(searchData).forEach((value) => {
            searchData[value] = "";
        })
        //clear DOM
        setToInitialState();
    }

    /**
     * clears id="response".innerHTML 
     * & sets response element to initial, empty state 
     * & sets init-state to true
     */
    const setToInitialState = () => {
        setOptions(getOptions(users.value));
        setSortBy("surname");
        setSortDirection("increase");
        setSearchResponse(null);
        setExpandAll(false);
        setInit(true);
        setHidden(false);
    };
    
    /**
     * parses response data (JSON object) to .csv - 
     * https://codingbeautydev.com/blog/javascript-convert-json-to-csv -
     * then calls downloadUsers(<csv.value>) 
     * @returns undefined if no valid response present 
     */
    const handleDownload = () => {
        if(!searchResponse || (searchResponse.length === 0)) {
            alert("keine Nutzer angezeigt");
            return;
        }
        const parser = new Parser({ delimiter: ',' });
        const userWithoutPic = searchResponse.map(({picture, ...keepAttrs}) => keepAttrs);
        const csv = parser.parse(userWithoutPic);
        downloadUsers(generateFileName(), csv);
    };

    /**
     * @returns dynamic file name consisting of all valid searchData key/value pairs + current date
     */
    const generateFileName = () => {
        let s = "VIC-TELEFONBUCH";
        Object.keys(searchData).forEach((value) => {
            if(searchData[value]) {
                s += `-[${value.toUpperCase()}=${searchData[value].replaceAll(" ", "_")}]`;  
            }
        });
        s += `-sortBy[${sortBy}, ${sortDirection}]`;
        s += `-[${new Date().toLocaleDateString()}].csv`;
        return s;
    };

    /**
     * download -> https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
     * @param {*} filename default file name (file format is set here -> e.g. ".csv")
     * @param {*} text to download
     */
    const downloadUsers = (filename, text) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
    };

    const handleLogout = (logoutType) => {
        if(window.confirm("Möchtest du dich wirklich abmelden?") && (logoutType === "redirect")) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/" // redirects the top level app after logout
            })
        }
    };
    
    /**
     * calls mailto protocol for all diplayed users
     * @returns undefined if no valid response present 
     */
    const sendMailToAll = () => {
        if(!searchResponse || (searchResponse.length === 0)) {
            alert("keine Mailadresse(n) ausgewählt");
            return;
        }
        if(window.confirm("Möchtest du wirklich eine Mail an alle angezeigten Nutzer senden?")) {
            let s = "mailto:";
            searchResponse.forEach((user, index) => {
                if(index < searchResponse.length - 1) {
                    s += `${user.mail}; `;
                } else {
                    s += user.mail;
                }
            });
            window.open(s);
        } 
    };

    const handleHide = () => {
        setHidden(hidden? false : true);
    };

    const handleShowAll = () => {
        handleReset();
        setSearchResponse(users.value);
        setInit(false);
    }

    const handlePrint = () => {
        if(searchResponse) {
            window.print();
        } else {
            alert("keine Nutzer angezeigt");
        }
    };

    const handleSortClick = (sortParam) => {
        if(sortParam !== sortBy) {
            setSortBy(sortParam);
            setSortDirection("increase");
        } else {
            setSortDirection(sortDirection === "increase" ? 
                "decrease" 
                :
                "increase"
            );
        }
    };

    /**
    * @returns displays all search criteria (for debugging purposes)
    */
    /*
    const renderRequest = () => {
        let s = "";
        let empty = true;
        Object.keys(searchData).forEach((value) => {
            if(searchData[value]) {
                s += `[${value.toLowerCase()} = "${searchData[value]}"] `; 
                empty = false;
            }
        });
        return s;
    };
    */
    
    return(
        <div>

            <div className="loggedInUser">
                <div>
                    angemeldet als - {name}
                </div>

                <div className="supportButton"
                    onClick={() => window.open("mailto:telefonbuch@vic-gmbh.de")} 
                    title="Mail an telefonbuch@vic-gmbh.de senden">
                    Feedback senden
                </div>
            </div>

            {/*FIXME: HIER MUSS EIN COMPONENT DRAUS GEMACHT WERDEN -> <FunctionalButtons /> */}
            <div id="buttons">

                <button type="button" onClick={() => handleLogout("redirect")} className="myNavButtons">
                    <box-icon
                        name="log-out" 
                        alt="zurücksetzen" 
                        title="Abmelden">
                    </box-icon>
                </button>

                <button type="button" onClick={handleReset} className="myNavButtons">
                    <box-icon
                        name="redo" 
                        flip="horizontal" 
                        alt="zurücksetzen" 
                        title="Formular zurücksetzen">
                    </box-icon>
                </button>

                <button type="button" onClick={handleShowAll} className="myNavButtons">
                    <box-icon
                        name="group"
                        alt="alle Mitarbeiter anzeigen" 
                        title="alle Mitarbeiter anzeigen">
                    </box-icon>
                </button>

                <button type="button" onClick={handleDownload} className="myNavButtons">
                    <box-icon
                        name="download" 
                        alt="download" 
                        title="als Datei herunterladen">
                    </box-icon>
                </button>

                <button 
                    type="button" 
                    onClick={handlePrint} 
                    className="myNavButtons">
                    <box-icon
                        name="printer" 
                        alt="print" 
                        title="drucken">
                    </box-icon>
                </button>

                <button type="button" onClick={sendMailToAll} className="myNavButtons">
                    <box-icon
                        name="mail-send"
                        alt="E-Mail an alle senden" 
                        title="E-Mail an alle angezeigten Nutzer senden">
                    </box-icon>
                </button>

                <button type="button" onClick={handleHide} className="myNavButtons">
                    <box-icon
                        name={hidden ? "hide" : "show"} 
                        alt={hidden ? "Suche anzeigen" : "Suche verbergen"}
                        title={hidden ? "Suche anzeigen" : "Suche verbergen"}>
                    </box-icon>
                </button>

            </div>

            <div hidden={hidden} className="header">

                <form 
                    method="post" 
                    id="searchForm" 
                    onChange={handleChange} 
                    autoComplete="off" 
                    className="myForm">
                    
                    <div className="gridElem">
                        <label htmlFor="name">Name</label>
                        <input id="name" name="name" placeholder="" />
                    </div>
                    
                    <div className="gridElem">
                        <label htmlFor="phone">Telefon</label>
                        <input type="number" id="phone" name="phone" />
                    </div>
                    
                    <div className="gridElem">
                        <label htmlFor="city">Standort</label>
                        <select id="city" name="city">
                            {options && options.citys.map((city, index) => {
                                return (
                                    <option value={city} key={city + index}>{city}</option>
                                );
                            })}
                        </select>
                    </div>
                               
                    <div className="gridElem">
                        <label htmlFor="companyName">Firma</label>
                        <select id="companyName" name="companyName">
                            {options && options.companys.map((company, index) => {
                                return (
                                    <option value={company} key={company + index}>{company}</option>
                                );
                            })}
                        </select>
                    </div>
                              
                    <div className="gridElem">
                        <label htmlFor="department">Abteilung</label>
                        <select id="department" name="department">
                            {options && options.departments.map((department, index) => {
                                return (
                                    <option value={department} key={department + index}>{department}</option>
                                );
                            })}
                        </select>
                    </div>
                     
                    <div className="gridElem">
                        <label htmlFor="jobTitle">Position</label>
                        <select id="jobTitle" name="jobTitle">
                            {options && options.jobs.map((job, index) => {
                                return (
                                    <option value={job} key={job + index}>{job}</option>
                                );
                            })}
                        </select>
                    </div>
                </form>

                {/*<div className="request"><i>{renderRequest()}</i></div>*/}

                {searchResponse ?
                    <div className="userCount">

                        <div>
                            <button 
                                type="button"
                                onClick={() => {expandAll ? setExpandAll(false) : setExpandAll(true)}}
                                className="expandAllButton">
                                    {/*@ts-ignore */}
                                    <box-icon
                                        name={expandAll ? "chevrons-up" : "chevrons-down"} 
                                        alt={expandAll ? "alle einklappen" : "alle ausklappen"}
                                        title={expandAll ? "alle einklappen" : "alle ausklappen"}
                                        className="expandAllButton">
                                    {/*@ts-ignore */}
                                    </box-icon>
                            </button>
                        </div>

                        <div className="count">
                            {searchResponse.length === 1 ?
                                    `1 Ergebnis`
                                    :
                                    `${searchResponse.length} Ergebnisse`
                            }
                        </div>
 
                    </div>
                    :
                    null
                }

                {searchResponse ?
                    <div className="propertyWrapper">
                        <span className="propertyNames">
                            <div 
                                className="sortElemWrapper"
                                onClick={() => handleSortClick("surname")}>
                                Name
                                <SortParam sortParam="surname" sortBy={sortBy} sortDirection={sortDirection} />
                            </div>
                            <div 
                                className="sortElemWrapper"
                                onClick={() => handleSortClick("jobTitle")}>
                                Position
                                <SortParam sortParam="jobTitle" sortBy={sortBy} sortDirection={sortDirection} />
                            </div>
                            <div 
                                className="sortElemWrapper"
                                onClick={() => handleSortClick("department")}>
                                Abteilung
                                <SortParam sortParam="department" sortBy={sortBy} sortDirection={sortDirection} />
                            </div>
                            <div 
                                className="sortElemWrapper"
                                onClick={() => handleSortClick("city")}>
                                Standort
                                <SortParam sortParam="city" sortBy={sortBy} sortDirection={sortDirection} />
                            </div>
                        </span>
                    </div>
                    :
                    null
                }
                
            </div>
                   
            {searchResponse && (searchResponse.length > 0) ?
                <UserList 
                    users={searchResponse} 
                    expandAll={expandAll} 
                    sortBy={sortBy} 
                    sortDirection={sortDirection} />
                :
                !init ? 
                    searchResponse ?
                        <h4>keine Ergebnisse gefunden</h4>
                        :
                        <span className="loading">
                            <box-icon 
                                name="loader-alt" 
                                animation="spin"
                                alt="loading"
                                size="md">
                            </box-icon>
                        </span>
                    : 
                    null
            }

        </div>
    );
}

function SortParam(props) {
    return (
        <>
            {props.sortBy === props.sortParam ? 
                <div className="sortElem">
                    {/*@ts-ignore */}
                    <box-icon 
                        name={props.sortDirection === "increase" ? "sort-a-z" : "sort-z-a"}
                        size="xs">
                    {/*@ts-ignore */}
                    </box-icon>
                </div>
                :
                null
            }
        </>
    )
}

/**
 * 
 * @param {*} users 
 * @returns dynamic object with all citys, companys, departments and jobs in the tenant
 */
const getOptions = (users) => {
    let citys = [""]; 
    let companys = [""];
    let departments = [""];
    let jobs = [""];

    users && users.forEach(user => {
        if(!citys.includes(user.city) && user.city) {
            citys.push(user.city);
        }
        if (!companys.includes(user.companyName) && user.companyName) {
            companys.push(user.companyName);
        }
        if (!departments.includes(user.department) && user.department) {
            let departmentArray = (user.department.split(", "));
            departmentArray.forEach(dep => {
                if(!departments.includes(dep)){
                    departments.push(dep);
                }
            });
        }
        if (!jobs.includes(user.jobTitle) && user.jobTitle) {
            jobs.push(user.jobTitle);
        }
    });
    citys.sort();
    companys.sort();
    departments.sort();
    jobs.sort();
    
    return {citys, companys, departments, jobs};
}
