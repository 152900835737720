/**
 * @author Raban Noack <raban.noack@vic-gmbh.net>
 */

import React from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
//@ts-ignore
import { Search } from "./components/searchForm/Search.jsx";

function App() {
  return (   
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Search />
      </MsalAuthenticationTemplate>
  );
}

export default App;
