import React from "react";
//@ts-ignore
import { UserOverview } from "./userOverwiew.tsx";

export function UserList(props) {

    const users = props.users;

    if(users.length === 0) {
        return <h4>keine Ergebnisse gefunden</h4>;
    }

    const getSortProp = (user) => {
        switch(props.sortBy) {
            case "surname":
                return user.surname;
            case "jobTitle":
                return user.jobTitle;
            case "department":
                return user.department;
            case "city":
                return user.city;
            default:
                return null;
        }
    };

    const sortUsers = (value1, value2, sortDirection: string) => {
        value1 = !value1 ? "" : value1;
        value2 = !value2 ? "" : value2
        if(value1 > value2) {
            return sortDirection === "increase" ? 1 : -1;
        }
        if(value1 < value2) {
            return sortDirection === "increase" ? -1 : 1;
        }
        return 0;
    }

    return (
        <div id="response" className="response">

            <ul className="searchResponse">
                {users.sort((a, b) => {
                    if(getSortProp(a) === getSortProp(b)) {
                        return sortUsers(a.surname, b.surname, "increase");
                    }
                    return sortUsers(getSortProp(a), getSortProp(b), props.sortDirection);
                }).map((user, index) => {
                    return (
                        <li key={user.displayName + index}>
                            <UserOverview user={user} expandAll={props.expandAll} />
                        </li>
                    );
                })}
            </ul>

        </div>
    );
}

